/* THEME COLORS */

$colors: (
    'primary': #1940ff,
    'primary-transparent': rgba(27, 38, 46, 0.5),
    'primary-hidden': rgba(27, 38, 46, 0),
    'secondary': #112544,
    'terniary': #1940ff,
    'top-bottom-bg':#37383d,
    'text-light': #ffffff,
    'text-dark': #000000,
    'white': #ffffff,
    'black': #000000,
    'error': #c90000,
    'error-light': #ffe9e9,
    'dark': #1b262e,
    'background': (
        'main': #ffffff,
        'alt': #f6f6f6,
        'dark': #36434d,
        'light': #efefef,
    ),
    'text': (
        'dark': #444,
        'darker': #1b262e,
        'light': #777,
        'placeholder': #b5d1ee,
        'subtitle': #daaf5c,
    ),
    'border': (
        'dark': #6f757b,
        'light': #ccc,
        'header': #d7d8d8,
    ),
    'nav': (
        'text':(
            'main': #444,
            'accent': #43474c,
            'dark' : #1b262e,
        ),
        'top': (
            'btn-hover': #352e73,
        ),
        'background': (
            'main': #1b262e,
            'main-half-opacity': rgba(27, 38, 46, 0.5),
            'main-opacity': rgba(27, 38, 46, 0),
            'accent': #1b262e,
        ),
    ),
    'footer':(
        'text': (
            'main': #8fa4b2,
            'accent': #6e7882,
            'social_icons': #2f363d,
            'block_title': #FFF,
        ),
        'background': (
            'main': #1b262e,
            'accent': #1b1e22,
        ),
    ),
    'calendar': (
        'header': (
            'background': color('primary'),
            'text': color('white')
        ),
        'default': (
            'background': #eee5e0,
            'text': #8a7768,
        ),
        'weekend': (
            'background': #CCC,
        ),
        'unavailable': (
            'background': color('primary'),
            'text': #621b00,
        ),
    ),
);

@function color($name, $subname: '', $deepname: '', $map: $colors) {
    @if map-has-key($map, $name) {
        @if type-of(map-get($map, $name))=="map" {
            @if type-of(map-deep-get($map, $name, $subname))=="map" {
                $name: map-deep-get($map, $name, $subname, $deepname);
                @if $name==null {
                    @warn "Unknown color name entered for 3rd arg of color function: `#{$deepname}`, in map: $colors.";
                }
            } @else {
                @if type-of(map-get($map, $name))=="map" {
                    $map: map-get($map, $name);
                    $name: map-get($map, $subname);
                    @if $name==null {
                        @warn "Unknown color name entered for 2nd arg of color function: `#{$subname}`, in map: $colors.";
                    }
                } @else {
                $name: map-deep-get($map, $name, $subname);
                @if $name==null {
                    @warn "Unknown color name entered for 2nd arg of color function: `#{$subname}`, in map: $colors.";
                }
            }
            }
        } @else {
            $name: map-get($map, $name) or $name;
        }

        @if map-has-key($map, $name) {
            $name: color($name);
        }

        @if type-of($name)=="list" {
            @if length($name)==2 {
                $cname1: nth($name, 1);
                $cname2: nth($name, 2);
                $name: color($cname1, $cname2);
            }

            @if length($name)==3 {
                $cname1: nth($name, 1);
                $cname2: nth($name, 2);
                $cname3: nth($name, 3);
                $name: color($cname1, $cname2, $cname3);
            }
        }

        @return $name;
    }

    @warn "Unknown color name entered for 1st arg of color function: `#{$name}`, in map: #{$map}.";
    @return null;
}

:root {
    /* BOOTSTRAP ROOT VARS
    // Uncomment to change vars
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #ffffff;
    --gray: #868e96;
    --gray-dark: #343a40;
    */
    --primary: #1870cb;
    --secondary: #adc400;
    --terniary: #1870b4;
    /*
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    */
    --dark: #475966;
    /*
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    */
}

$colors-header: (
    'header-top-bg': color('white'),
    'header-nav': color('black'),
    'header-container-bg': color('top-bottom-bg'), // NE homepage
    'header-top-text': color('black'),
    'header-border': rgba(255, 255, 255, 0.1),
    'header-nav-dropdown-li-hover': color('secondary'),
    'header-i': color('primary'),
    'language-dropdown-bg': color('white'),
    'language-dropdown-li-hover': color('primary'),
    'btn-hamburger': color('white'),
    'header-bg': color('primary-transparent'),
    'header-bg-hidden': color('top-bottom-bg'),
    'header-active': color('primary'),
    'header-bg-transparent': color('primary-hidden'),
);

$colors-footer: (
    'footer-bg': color('top-bottom-bg'),
    'footer-section-titles': color('white'),
    'footer-title-underline': color('primary'),
    'footer-text': color('white'),
    'footer-hover-text': color('secondary'),
    'footer-contact-icons': color('white'),
    'footer-social-i': color('white'),
    'footer-social-hover': color('primary'),
    'footer-hover-alt': color('secondary'),
    'footer-btn-border': color('secondary'),
    'footer-focus': color('secondary'),
    'footer-social-findus-bg':color('white'),
    'footer-social-findus-i':color('top-bottom-bg'),
);